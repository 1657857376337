/* global YT, Vimeo */

import { enqueueYT } from '@module/embed';
import { getYouTubeID } from '@module/util';

export default function initShowcaseVideo() {
    document.querySelectorAll('.showcaseplayer').forEach(parent => {
        const url = parent.dataset.videoUrl;
        const showcase_wrapper = document.getElementById('showcase');

        let video;
        let player;
        let show_video_on_mobile = parent.dataset.videoOnMobile.toLowerCase() === 'true';
        let aspect_ratio = 16 / 9;

        if (showcase_wrapper) {
            aspect_ratio = showcase_wrapper.clientHeight / showcase_wrapper.clientWidth;
        }

        if (!show_video_on_mobile && aspect_ratio.toFixed(1) >= 0.7) {
            return;
        }

        function scaleVideo() {
            if (!video) {
                return;
            }

            let parent_aspect = parent.clientWidth / parent.clientHeight;
            let vid_aspect = 16 / 9;
            let current_height;

            if (parent_aspect > vid_aspect) {
                // parent is landscape-ishier than the video
                video.style.transform = 'scale(' + parent.getBoundingClientRect().width / video.clientWidth + ')';
            } else {
                current_height = video.clientWidth / vid_aspect; // because the iframe is still probably 720 high
                video.style.transform = 'scale(' + video.clientHeight / current_height + ')';
            }
        }
        function muteYoutube(event) {
            event.target.mute();
        }
        function handleYTState(event) {
            let YTP = event.target;
            let rewindTO;

            if (event.data === YT.PlayerState.PLAYING){
                let remains = YTP.getDuration() - YTP.getCurrentTime();
                if (rewindTO) {
                    clearTimeout(rewindTO);
                }
                // To remove black screen end of the loop videos - rewind the video with seeking to a frame.
                rewindTO = setTimeout(function(){
                    YTP.seekTo(0);
                }, (remains - 0.3) * 1000);
                parent.classList.add('playing');
                video = parent.children[0];
                scaleVideo();
            }
        }

        function startVimeo() {
            // vimeo inserts the player in the placeholder
            parent.querySelector('.placeholder').remove();
            player = new Vimeo.Player(parent, {
                url: url,
                width: 1920,
                height: 1080,
                background: true,
                byline: false,
                muted: true,
                title: false
            });
            player.on('play', function() {
                parent.classList.add('playing');
                video = parent.children[0];
                scaleVideo();
            });

            player.on('error', function() {
                parent.remove();
            });
            player.on('pause', function() {
                // autoplay is blocked by the browser so remove the player
                parent.remove();
            });
        }

        window.addEventListener('resize', () => {
            scaleVideo();
        });

        if (url.includes('vimeo')) {
            if (typeof Vimeo !== 'undefined') {
                startVimeo();
            } else {
                const tag = document.head.querySelector('[src="https://player.vimeo.com/api/player.js"]');
                if (! tag) {
                    const script = document.createElement('script');
                    script.setAttribute('src', 'https://player.vimeo.com/api/player.js');
                    script.onload = startVimeo;
                    document.head.appendChild(script);
                } else {
                    tag.addEventListener('load', startVimeo);
                }
            }

        } else {
            const tag = document.head.querySelector('[src="https://www.youtube.com/iframe_api"]');
            if (! tag) {
                const script = document.createElement('script');
                script.setAttribute('src', 'https://www.youtube.com/iframe_api');
                document.head.appendChild(script);
            }
            enqueueYT(() => {
                new YT.Player(parent.querySelector('.placeholder'), {
                    width: 1920,
                    height: 1080,
                    videoId: getYouTubeID(url),
                    events: {
                        'onReady': muteYoutube,
                        'onError': parent.remove,
                        'onStateChange': handleYTState
                    },
                    playerVars: {
                        autoplay: 1,
                        controls: 0,
                        iv_load_policy: 3,
                        modestbranding: 1,
                        playsinline: 1,
                        loop: 1,
                        playlist: getYouTubeID(url)
                    }
                });
            });
        }
    });
}
