export default function initBasCineville() {
    /*
        For all tickets for Cineville a code needs to be provided.
        This inserts the required fields into the form
    */
    let form = document.getElementById('ticketsForm');
    if (! form) {
        return;
    }

    let cineville_prices = {};
    let fieldset_key = 'cineville-codes';
    let input_key = function(price_id, counter) {
        return 'cineville-' + price_id + '[' + counter + ']';
    };

    function makeCinevilleInputs() {
        if (document.getElementById(fieldset_key)) {
            document.getElementById(fieldset_key).remove();
        }

        if (Object.keys(cineville_prices).length < 1) {
            return;
        }

        let fieldset = document.createElement('fieldset');
        fieldset.setAttribute('id', fieldset_key);

        let line = document.createElement('hr');

        let title = document.createElement('h4');
        title.innerText = form.dataset.cinevilleTitle;

        let message = document.createElement('p');
        message.innerText = form.dataset.cinevilleMessage;

        fieldset.appendChild(line);
        fieldset.appendChild(title);
        fieldset.appendChild(message);

        function preventReuse() {
            let cineville_used = [];
            let inputs = document.querySelectorAll('#' + fieldset_key + ' input');

            if (inputs.length < 2) {
                return true;
            }

            inputs.forEach(function(input){
                input.setCustomValidity('');
                if (!input.value) {
                    // user is not ready yet
                    return true;
                }
                if (cineville_used.indexOf(input.value) > -1) {
                    input.setCustomValidity(form.dataset.cinevilleMessage);
                    input.reportValidity();
                    return false;
                } else {
                    cineville_used.push(input.value);
                }
            });
        }

        Object.keys(cineville_prices).forEach(price => {
            const count = cineville_prices[price];
            if (count < 1) {
                return;
            }

            let i = 0;
            while (i < count) {
                i++;

                let key = input_key(price, i);

                let group = document.createElement('div');
                group.classList.add('form-group');

                let label = document.createElement('label');
                label.setAttribute('for', key);
                label.innerText = 'Cineville code';

                let input = document.createElement('input');
                input.setAttribute('name', key);
                input.setAttribute('id', key);
                input.setAttribute('type', 'text');
                input.setAttribute('pattern', '^CP\\$[0-9]+');
                input.setAttribute('required', 'true');

                input.addEventListener('keyup', function(){
                    preventReuse();
                });

                group.appendChild(label);
                group.appendChild(input);
                fieldset.appendChild(group);
            }
        });

        form.insertBefore(fieldset, form.querySelector('.footer'));
    }

    form.querySelectorAll('select[data-cineville="true"]').forEach(function(select){
        select.addEventListener('change', function(){
            if (Number(select.value) > 0) {
                cineville_prices[select.name] = Number(select.value);
            } else {
                delete cineville_prices[select.name];
            }
            makeCinevilleInputs();
        });

    });
}
