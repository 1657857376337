import initBelgianAddresses from './belgian_addresses';
import basMaxTickets from './bas_max_tickets';
import checkDeliveryMethods from './check_deliverymethods';
import initAbandondedCartBanner from './abandoned_cart_banner';
import initCart from './cart';
import initCendris from './cendris';
import initCinemaPlanner from './cinema_planner';
import initCoursesAgeFilter from './courses_age_filter';
import initFilterResets from './filter_resets';
import initFilters from './filters';
import initFormCheckCancellation from './formcheck_cancellation';
import initFormCheckCheckboxes from './formcheck_checkboxes';
import initFormCheckEmail from './formcheck_email';
import initFormCheckPassword from './formcheck_password';
import initFormCheckValidationMsg from './formcheck_validationmsg';
import initFormDatePicker from './form_datepicker';
import initGlobal from './_index';
import initIBANValidation from './iban_check';
import initIframeSSO from './iframe_sso';
import initLoginPassword from './login_password';
import initMagicLinkForm from './magic_link_form';
import initNewsletterSubs from './newsletter_subs';
import initOptins from './optins';
import initOrderAgreement from './order_agreement';
import initOrderItems from './order_items';
import initOrderPhoneInput from './order_phonenumber_input';
import initPopovers from './popovers';
import initPopupBanners from './popup_banners';
import initPreventLeave from './prevent_leave';
import initPricePromotion from './price_promotion';
import initRecaptcha from './recaptcha';
import initShowcaseSlideShow from './showcase_slideshow';
import initShowcaseVideo from './showcase_video';
import initSidebarFilters from './filters_sidebar';
import initSimpleFilters from './filters_simple';
import initSubmitLockout from './submit_lockout';
import initTicketListCancellation from './ticketlist_cancellation';
import initTicketListExtras from './ticketlist_extras';
import initTRFilters from './filters_TR';
import initVoucherPayment from './voucher_payment';
import initWaitingList from './waitinglist';
import initCancellation from './cancellation';
import initWishlist from './wishlist';
import initBasCineville from './bas_cineville';
import { fillExtrasSelect } from './bas_extras';
import { initPhoneValidation, initUserDetailsForm } from './formcheck_gegevensForm';

import initComponents from './Components/index';
import initParts from './Parts/index';

document.addEventListener('DOMContentLoaded', function() {
    basMaxTickets();
    checkDeliveryMethods();
    initAbandondedCartBanner();
    initBasCineville();
    initBelgianAddresses();
    initCart();
    initCendris();
    initCinemaPlanner();
    initComponents();
    initCoursesAgeFilter();
    initFilterResets();
    initFilters();
    initFormCheckCancellation();
    initFormCheckCheckboxes();
    initFormCheckEmail();
    initFormCheckPassword();
    initFormCheckValidationMsg();
    initFormDatePicker();
    initGlobal();
    initIBANValidation();
    initIframeSSO();
    initLoginPassword();
    initMagicLinkForm();
    initNewsletterSubs();
    initOptins();
    initOrderAgreement();
    initOrderItems();
    initOrderPhoneInput();
    initParts();
    initPhoneValidation();
    initPopovers();
    initPopupBanners();
    initPreventLeave();
    initPricePromotion();
    initRecaptcha();
    initShowcaseSlideShow();
    initShowcaseVideo();
    initSidebarFilters();
    initSimpleFilters();
    initSubmitLockout();
    initTicketListCancellation();
    initTicketListExtras();
    initTRFilters();
    initUserDetailsForm();
    initVoucherPayment();
    initWaitingList();
    initCancellation();
    initWishlist();

    // Wrapping legacy jQuery handlers here solves some import bugs
    $('#ticketsForm-kaartsoorten select').change(function() {
        fillExtrasSelect();
    });

});
